import React, { useEffect } from 'react'
import classes from './Login.module.scss'
import { useAppDispatch } from '../../store/hooks'
import { IconEye, IconEyeClosed } from '../../utils/icons'
import { authLogin, authLogout } from '../../store/auth/thunk'
import { ReactComponent as Login } from '../../assets/images/login.svg'
import { Button, Col, Form, Input, Row, Typography } from 'antd'

interface ILogin {
  login: string
  password: string
}

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const onSubmit = (data: ILogin) => {
    dispatch(authLogin(data))
  }

  const hiddenHandler = () => {
    console.log('CLICK HIDDEN')
  }

  useEffect(() => {
    dispatch(authLogout())
  }, [])

  return (
    <div className={classes.container}>
      <Row className={classes.row}>
        <Col span={12} className={classes.col}>
          <div className={classes.bg}>
            {/* <Login /> */}
            <div className={classes.logo} />
            <h1>CRMBRO</h1>
          </div>
        </Col>
        <Col span={12} className={classes.col}>
          <div className={classes.wrapper}>
            <Typography.Title level={3}>Войти в Личный аккаунт</Typography.Title>
            <Form
              name='basic'
              className={classes.form}
              // autoComplete='off'
              onFinish={onSubmit}
            >
              <Form.Item
                rules={[
                  { required: true, message: 'Введите логин' },
                  { min: 3, message: 'Минимальная длинна 3 символа' },
                ]}
                validateTrigger='onSubmit'
                name='login'
              >
                <Input placeholder='Логин' className='input-login' />
              </Form.Item>
              <Form.Item
                name='password'
                validateTrigger='onSubmit'
                rules={[
                  { required: true, message: 'Введите пароль' },
                  { max: 10, message: 'Длинна не больше 10 символов' },
                  { min: 3, message: 'Минимальная длинна 3 символа' },
                ]}
              >
                <Input.Password
                  placeholder='Пароль'
                  className='input-login'
                  iconRender={(visible) =>
                    visible ? (
                      <span>
                        <IconEye width='22' height='22' fill='var(--color-main)' />
                      </span>
                    ) : (
                      <span>
                        <IconEyeClosed width='22' height='22' fill='var(--color-main)' />
                      </span>
                    )
                  }
                />
              </Form.Item>
              <Button className={classes.submit} type='primary' htmlType='submit'>
                Войти
              </Button>
            </Form>
          </div>
          <div className={classes.hidden} onClick={hiddenHandler}></div>
        </Col>
      </Row>
    </div>
  )
}

export default LoginPage
