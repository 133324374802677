export enum ChatsType {
  JIVO_CHAT = 'jivo',
  LCAB = 'lcab',
  CHATRA = 'chatra',
}

export const chatSourceLink = (type?: ChatsType | null) => {
  switch (type) {
    case ChatsType.JIVO_CHAT:
      return 'https://www.jivo.ru/'
    case ChatsType.LCAB:
      return 'https://lcab.talk-me.ru/cabinet/login'
    case ChatsType.CHATRA:
      return 'https://chatra.com/ru/'
  }
  return ''
}

const checkJivoChat = () => {
  return Boolean(document.querySelector('script[src^="//code.jivosite.com/widget/"]'))
}

const checkLcabChat = () => {
  return Boolean(document.getElementById('lcabScript'))
}

const checkChatraChat = () => {
  return Boolean(document.getElementById('chatraScript'))
}

const removeJivoChat = (token?: string) => {
  const oldScript1 = document.querySelector(`script[src^="//code.jivosite.com/widget/${token}"]`)
  if (oldScript1) {
    return true
  }
  const oldScript = document.querySelector('script[src^="//code.jivosite.com/widget/"]')
  if (oldScript) {
    oldScript.remove()
    return null
  }
  return false
}

const removeLcabChat = () => {
  const oldScript = document.getElementById('lcabScript')
  if (oldScript) {
    oldScript.remove()
    return null
  }
  return false
}

const removeChatraChat = (token?: string) => {
  const oldScript = document.getElementById('chatraScript')
  if (token && oldScript?.innerHTML.includes(token)) {
    return true
  }
  const oldScript2 = document.querySelector('script[src^="https://call.chatra.io/chatra.js"]')

  if (oldScript || oldScript2) {
    oldScript && oldScript.remove()
    oldScript2 && oldScript2.remove()
    return null
  }
  return false
}

const addChatraChat = (token: string) => {
  const result = removeChatraChat(token)
  if (!result) {
    const script = document.createElement('script')
    script.id = 'chatraScript'
    script.innerHTML = `(function(d, w, c) {
        w.ChatraID = '${token}';
        var s = d.createElement('script');
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');`
    script.async = true

    document.head.appendChild(script)
    return true
  }
}

const addLcabChat = (token: string) => {
  removeLcabChat()

  //   const script = document.createElement('script')
  //   script.id = 'lcabScript'
  //   script.src = `https://lcab.talk-me.ru/support/support.js?h=${token}`
  //   script.async = true
  //   document.head.appendChild(script)
  //   console.log('addJivoChat', token)

  const script = document.createElement('script')
  script.id = 'lcabScript'
  script.innerHTML = `(function(){(function c(d,w,m,i) {
            window.supportAPIMethod = m;
            var s = d.createElement('script');
            s.id = 'supportScript';
            var id = '${token}';
            s.src = (!i ? 'https://lcab.talk-me.ru/support/support.js' : 'https://static.site-chat.me/support/support.int.js') + '?h=' + id;
            s.onerror = i ? undefined : function(){c(d,w,m,true)};
            w[m] = w[m] ? w[m] : function(){(w[m].q = w[m].q ? w[m].q : []).push(arguments);};
            (d.head ? d.head : d.body).appendChild(s);
        })(document,window,'TalkMe')})();`
  script.async = true
  document.head.appendChild(script)
  return true
}

const addJivoChat = (token: string) => {
  const result = removeJivoChat(token)
  if (!result) {
    const script = document.createElement('script')
    script.src = `//code.jivosite.com/widget/${token}`
    script.async = true

    document.head.appendChild(script)
    return true
  }
}

const removeAllChats = () => {
  const res1 = removeJivoChat()
  const res2 = removeLcabChat()
  const res3 = removeChatraChat()
  if (res1 === null || res2 === null || res3 === null) window.location.reload()
  return true
}

export const updateChatScript = (tokenData: { token: string; type: ChatsType } | undefined) => {
  if (!tokenData || !tokenData.token) {
    removeAllChats()
    return
  }
  switch (tokenData.type) {
    case ChatsType.JIVO_CHAT: {
      const isLcab = checkLcabChat()
      const isChatra = checkChatraChat()
      if (isLcab || isChatra) {
        window.location.reload()
      }
      addJivoChat(tokenData.token)
      return
    }
    case ChatsType.LCAB: {
      const isJivo = checkJivoChat()
      const isChatra = checkChatraChat()
      if (isJivo || isChatra) {
        window.location.reload()
      }
      addLcabChat(tokenData.token)
      return
    }
    case ChatsType.CHATRA: {
      const isJivo = checkJivoChat()
      const isLcab = checkLcabChat()
      if (isJivo || isLcab) {
        window.location.reload()
      }
      addChatraChat(tokenData.token)
      return
    }
    default: {
      removeAllChats()
    }
  }
}
