import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { ICountry, ISettingsStatus } from '../../interfaces/settings'
import {
  deleteStatus,
  removeIsStatusesLoading,
  setBanks,
  setCountries,
  setIsStatusesLoading,
  setStatuses,
} from './reducer'
import { errorMapper } from '../../utils/errorMapper'
import { ErrorMessages } from '../../constants/errors'
import { useMessage } from '../../hooks/useMessage'
import { IDocBank } from '../../interfaces/templates.interface'

export const getSettings = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ISettingsStatus[]> = await axiosInstance.get(
      serverPath.CRM_SETTINGS,
    )
    dispatch(setStatuses(data))
  } catch (e) {
    console.log(e)
  }
}

export const updateStatusById =
  (item: Partial<ISettingsStatus> & { swapId?: string }) => async (dispatch: AppDispatch) => {
    try {
      item.id && dispatch(setIsStatusesLoading(item.id))
      await axiosInstance.patch(serverPath.CRM_SETTINGS + '/' + item.id, item)
    } catch (e) {
      console.log(e)
    } finally {
      item.id && dispatch(removeIsStatusesLoading(item.id))
    }
  }

export const deleteStatusById = (id: string) => async (dispatch: AppDispatch) => {
  const { error } = useMessage()
  try {
    await axiosInstance.delete(serverPath.CRM_SETTINGS + '/' + id)
    dispatch(deleteStatus(id))
  } catch (e) {
    error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.REMOVE_DATA)
    console.log(e)
  }
}

export const getCountries = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ICountry[]> = await axiosInstance.get(serverPath.COUNTRIES)
    dispatch(setCountries(data))
  } catch (e) {
    console.log(e)
  }
}

export const getAllBanks = (countryId?: string) => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { rows },
    }: AxiosResponse<{ rows: IDocBank[]; count: number }> = await axiosInstance.get(
      serverPath.GENERATOR_BANKS,
      {
        params: {
          countryId,
          pageSize: 1000,
          page: 1,
        },
      },
    )
    dispatch(setBanks(rows))
  } catch (e) {
    console.log(e)
  }
}
