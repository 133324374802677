export enum SocketEvents {
  USERS_STATUS = 'updateUserStatus',
  MSG = 'receive_message',
  RECALL = 'recall',
  UPDATE_RECALL = 'updateRecall',
  CREATE_TRANSMIT = 'createTransmit',
  LOGOUT = 'logout',
  NOTIFICATION = 'notification',
  UPDATE_SETTINGS = 'updatePermission',
  SUBSCRIPTION = 'subscription',
  UPDATE_MAP_STATUSES = 'update_map_statuses',
  UPDATE_JIVO_TOKEN = 'update_jivo_token',
  UPDATE_CRM_BALANCE = 'updateCrmBalance',
  FINISH_CHECK_PHONES = 'finishCheckPhone',
  UPDATE_COUNTRIES = 'updateCountries',
  UPDATE_USERS_PERMISSIONS = 'updateUsersPermission',
}
