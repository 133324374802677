import { BaseColumn, BaseDefaultStatusesColumn } from '../enums/base'
import { ExpensesReasonEnum } from '../enums/salary'
import { SubscriptionType } from '../enums/subscription'
import { ISettingsStatus } from '../interfaces/settings'
//remove
// export const statusesArray: Exclude<
//   BaseDefaultStatusesColumn,
//   BaseDefaultStatusesColumn.RECALL | BaseDefaultStatusesColumn.TRANSMIT
// >[] = [
//   BaseDefaultStatusesColumn.SMARTERME,
//   BaseDefaultStatusesColumn.SLIV,
//   BaseDefaultStatusesColumn.NOMONEY,
//   BaseDefaultStatusesColumn.RETRIEVED,
//   BaseDefaultStatusesColumn.UNDERCALL,
//   BaseDefaultStatusesColumn.REMOVE,
//   BaseDefaultStatusesColumn.UNDEFINED,
//   // BaseDefaultStatusesColumn.IN_WORK,
//   BaseDefaultStatusesColumn.REMAINDER,
// ]

export const statusMapper = (status?: BaseDefaultStatusesColumn | string | null): string => {
  if (!status) return 'нет статуса'
  if (status.includes('additional_status')) {
    return 'Доп. статус' + status.split('additional_status')[1]
  }
  switch (status) {
    case BaseDefaultStatusesColumn.SMARTERME:
      return 'Умник'
    case BaseDefaultStatusesColumn.SLIV:
      return 'Слив'
    case BaseDefaultStatusesColumn.NOMONEY:
      return 'Нет средств'
    case BaseDefaultStatusesColumn.RETRIEVED:
      return 'Списали'
    case BaseDefaultStatusesColumn.UNDERCALL:
      return 'Недозвон'
    case BaseDefaultStatusesColumn.REMOVE:
      return 'Удалить'
    case BaseDefaultStatusesColumn.UNDEFINED:
      return 'Не существует'
    case BaseDefaultStatusesColumn.REMAINDER:
      return 'Непрозвон'
    case BaseDefaultStatusesColumn.PENDING:
      return 'В ожидании'
    case BaseDefaultStatusesColumn.IN_WORK:
      return 'В работе'
    case BaseDefaultStatusesColumn.RECALL:
      return 'Перезвон'
    case BaseDefaultStatusesColumn.TRANSMIT:
      return 'Передать'
    default:
      return status
  }
}
//remove
// export const statusesSelect = [
//   BaseDefaultStatusesColumn.SMARTERME,
//   BaseDefaultStatusesColumn.SLIV,
//   BaseDefaultStatusesColumn.NOMONEY,
//   BaseDefaultStatusesColumn.RETRIEVED,
//   BaseDefaultStatusesColumn.UNDERCALL,
//   BaseDefaultStatusesColumn.REMOVE,
//   BaseDefaultStatusesColumn.UNDEFINED,
//   BaseDefaultStatusesColumn.REMAINDER,
// ].map((el) => ({ value: el }))

export const expensesReasonMapper = (type: ExpensesReasonEnum | undefined): string => {
  switch (type) {
    case ExpensesReasonEnum.PHONE:
      return 'Телефония'
    case ExpensesReasonEnum.CRM:
      return 'CRM'
    case ExpensesReasonEnum.ARENDA:
      return 'Аренда'
    case ExpensesReasonEnum.BASE:
      return 'Базы'
    case ExpensesReasonEnum.ZONT:
      return 'Зонт'
    case ExpensesReasonEnum.ZP_IT:
      return 'Зп IT'
    case ExpensesReasonEnum.OTHER:
      return 'Другое'
    default:
      return 'нет такого'
  }
}

export const additionInfoNameMapper = (type: BaseColumn | undefined): string => {
  switch (type) {
    case BaseColumn.ADDITION_FIELD_1:
      return 'Доп. поле 1'
    case BaseColumn.ADDITION_FIELD_2:
      return 'Доп. поле 2'
    case BaseColumn.ADDITION_FIELD_3:
      return 'Доп. поле 3'
    case BaseColumn.ADDITION_FIELD_4:
      return 'Доп. поле 4'
    case BaseColumn.ADDITION_FIELD_5:
      return 'Доп. поле 5'
    case BaseColumn.ADDITION_FIELD_6:
      return 'Доп. поле 6'
    case BaseColumn.ADDITION_FIELD_7:
      return 'Доп. поле 7'
    case BaseColumn.ADDITION_FIELD_8:
      return 'Доп. поле 8'
    case BaseColumn.ADDITION_FIELD_9:
      return 'Доп. поле 9'
    case BaseColumn.ADDITION_FIELD_10:
      return 'Доп. поле 10'
    default:
      return 'нет такого'
  }
}

export const subscriptionMapper = (val: SubscriptionType | undefined) => {
  if (!val) return 'Нет'
  switch (val) {
    case SubscriptionType.FIRST:
      return 'Первый'
    case SubscriptionType.SECOND:
      return 'Второй'
    case SubscriptionType.THIRD:
      return 'Третий'
  }
}

export const getStatusTranslatedName = (status: ISettingsStatus) => {
  if (status.mapName) return status.mapName
  return statusMapper(status.name)
}
