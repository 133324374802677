import React, { HTMLAttributes } from 'react'
import cn from 'classnames'
import cls from './InDebtBtn.module.scss'

interface InDebtBtnProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export const InDebtBtn: React.FC<InDebtBtnProps> = ({ className, ...props }) => {
  return (
    <div className={cn([className, cls.InDebtBtn])} {...props}>
      Взять в кредит 1 день
    </div>
  )
}
